import { Link } from "gatsby"
import React from "react"
import styles from "./features.module.css"

import iconMessages from "../images/iconMessages.svg"
import iconAnnonces from "../images/iconAnnonces.svg"
import iconIncidents from "../images/iconIncidents.svg"

const features = ({noButton}) => (
  <div className={styles.container}>
    <h2>Les fonctionnalités</h2>
    <ul>
      <li><img width="71" heigth="71" src={iconIncidents}/>Incidents</li>
      <li><img width="71" heigth="71" src={iconAnnonces}/>Annonces</li>
      <li><img width="71" heigth="71" src={iconMessages}/>Messages</li>
    </ul>
    <div className={styles.btn_container}>
      {!noButton?(
        <Link className={styles.btn} to="/fonctionnalites">Découvrir les fonctionnalités</Link>
      ):null}
    </div>
  </div>
)

export default features




