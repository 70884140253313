import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import Audience from "../components/audience"
import Features from "../components/features"
import RDV from "../components/rdv"
import Download from "../components/download"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="CONNECTE LES RESIDENTS AVEC LEUR SYNDIC" description={'Application mobile et web qui facilite les échanges entre les résidents et leur syndic.'}/>
    <Audience/>
    <Download/>
    <RDV/>
    <Features/>
  </Layout>
)

export default IndexPage
