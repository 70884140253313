import { Link } from "gatsby"
import React from "react"
import styles from "./rdv.module.css"

const RDV = () => (
  <div className={styles.container}>
    <Link className={styles.btn} to="/demo">Demander une demo</Link>
  </div>
)

export default RDV
