import { Link } from "gatsby"
import React from "react"
import styles from "./download.module.css"

import iconSyndic from "../images/iconSyndic+.svg"
import googlePlay from "../images/google-play.svg"
import appStore from "../images/app-store.svg"

const donwload = () => (
  <div className={styles.container}>
    <h2>Télécharger<br/>l’application Syndic +</h2>
    <a href="https://syndic-en-un-clic.fr/app" className={styles.icon}>
      <img width="100" heigth="100" src={iconSyndic}/>
      Syndic +
    </a>
    <div className={styles.buttons}>
      <a href="https://play.google.com/store/apps/details?id=com.syndicenunclic.syndic"><img src={googlePlay}/></a>
      <a href="https://apps.apple.com/us/app/syndic/id1498192384"><img src={appStore}/></a>
    </div>
  </div>
)

export default donwload
