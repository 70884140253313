import { Link } from "gatsby"
import React from "react"
import styles from "./audience.module.css"
import Button from "../components/button"

const audience = () => (
  <div className={styles.container}>
    <h1 className={styles.baseline}>
      <span>CONNECTE</span><br/>
      <span className={styles.residents}>LES RESIDENTS </span><br/>
      <span className={styles.avec}>AVEC LEUR </span><br/>
      <span className={styles.syndic}>SYNDIC</span>
    </h1>
    <div className={styles.cards}>
      <div className={styles.card}>
        <h2>Résidents</h2>
        <p>Restez informés de votre vie d'immeuble et contactez facilement votre syndic en cas de besoin.</p>
        <Button to="/residents/">Je suis résident</Button>
      </div>
      <div className={styles.card}>
        <h2>Syndic</h2>
        <p>Informez les résidents de l'actualité de votre immeuble et communiquez facilement avec les résidents.</p>
        <Button to="/gestionnaires/">Je suis gestionnaire</Button>
      </div>
    </div>
  </div>
)

export default audience
